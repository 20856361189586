/* eslint-disable import/no-unresolved */
import { dispatchEvent } from '@niaratech/niara-react-components/lib/events'
import axios from 'axios'
import metadata from '!!json-loader!val-loader!./metadata'
import metadataUrl from '!!file-loader?{"name": "metadata.json"}!val-loader!./metadata'

let _validity = new Date().getTime()
const UPDATE_PERIOD = (2 * 60 * 1000) / 10 // 2 minutos
const updateValidity = () => (_validity = new Date().getTime() + UPDATE_PERIOD)
const needTest = () => _validity < new Date().getTime()

const intervalId = setInterval(() => {
  if (needTest()) {
    updateValidity()
    axios
      .get(metadataUrl, {
        headers: {
          'cache-control': 'no-cache',
        },
      })
      .then((success) => {
        const loadedmetadata = success.data
        if (metadata.build !== loadedmetadata.build) {
          dispatchEvent({
            type: 'niaraversionchanged',
            detail: { envChanged: metadata.env !== loadedmetadata.env },
          })
          clearInterval(intervalId)
        }
      })
      .catch(() => {
        // Erro ao buscar o metadata.json. Ignorar - provavelmente falha de conexão.
      })
  }
}, UPDATE_PERIOD)
